import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ModalImgCss from "./modal.module.css"

const Modal = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "modal.jpg" }) {
      childImageSharp {
        fixed(width: 800) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} className={ModalImgCss.imagen}/>
}

export default Modal
