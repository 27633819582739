import React, { Component } from "react"
import Layout from "../components/layout"
import Logo from "../components/images/logo/logo"
import SEO from "../components/seo"
import indexCss from "../css/index.module.css"
import { Link } from "gatsby"
import Modal from "../components/modalIndex/modal"


class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      word: "ADAPTABLE",
    }
  }

  componentDidMount() {
    let titulos = [
      "Adaptables",
      "Creativos",
      "Startup",
      "Inteligencia",
      "Comprometidos",
      "Extraordinario",
      "Solucionadores",
      "Eficaces",
      "Transparentes",
    ]

    let beword = document.getElementById("title")
    if (beword != null) {
      var index = 0
      setInterval(function() {
        if (index < titulos.length) {
          index = index + 1
        } else {
          index = 0
        }
        beword.innerHTML = titulos[index]
      }, 800)
    }
  }

  render() {
    return (
      <div className={indexCss.background}>
        <Layout siteTitle="Home">
          <Link to="/Develop/Web" className={indexCss.left}>
            ← DEVELOP
          </Link>
          <div className={indexCss.puntitos}></div>
          <div className={indexCss.lineas}></div>
          <SEO title="Home" />
          <Logo />
          <h1 className={indexCss.titulo}>
            CAMBIA TU MUNDO, SE
            <br />
            <span id="title">{this.state.word}</span>
          </h1>
          <Modal></Modal>
          <Link to="/Design/Marca" className={indexCss.right}>
            DESIGN →
          </Link>
        </Layout>
      </div>
    )
  }
}

export default IndexPage
