import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import logoCss from "./logo.module.css";

const Logo = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "logo-engrane.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} className={logoCss.logo}/>
}

export default Logo
