import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalIMG from "../../components/images/modal/modal"

function ModalIndex() {
    const [show, setShow] = useState(true);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch static backdrop modal
        </Button> */}
  
        <Modal
        size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <ModalIMG></ModalIMG>
            
          </Modal.Body>
          {/* <Modal.Footer>
           
            <Button variant="primary">Understood</Button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
  
  export default ModalIndex;